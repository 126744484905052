import _ from 'lodash'
import { PlatformModel, SiteAssetsResourceType, PlatformLogger, AppStructureWithFeatures, PageFeaturesConfig } from '@wix/thunderbolt-symbols'
import { SiteAssetsClientAdapter } from 'thunderbolt-site-assets-client'
import { BootstrapData } from '../types'
import { MasterPageId } from './constants'
import { errorPagesIds } from '@wix/thunderbolt-commons'

type FeaturesResponse = {
	props: PageFeaturesConfig
	structure: AppStructureWithFeatures
}
type PageConfig = Record<string, object>
export type Models = {
	structureModel: AppStructureWithFeatures['components']
	propsModel: PageFeaturesConfig['render']['compProps']
	platformModel: PlatformModel
	pageConfig: PageFeaturesConfig
	getCompIdByWixCodeNickname: (nickname: string) => string
	getPageIdByCompId: (compId: string) => string
	getControllerTypeByCompId: (compId: string) => string | undefined
}

export default function({ bootstrapData, logger, siteAssetsClient, handlers }: { bootstrapData: BootstrapData; logger: PlatformLogger; siteAssetsClient: SiteAssetsClientAdapter; handlers: any }) {
	const fetchModel = <T>(resourceType: SiteAssetsResourceType, isMasterPage: boolean): Promise<T> =>
		logger.runAsyncAndReport(`getModel_${resourceType}${isMasterPage ? `_${MasterPageId}` : ''}`, () => {
			const pageCompId = isMasterPage ? MasterPageId : `${bootstrapData.currentPageId}`
			const isErrorPage = !!errorPagesIds[pageCompId]

			const pageJsonFileNames = bootstrapData.siteAssetsClientInitParams.siteScopeParams.pageJsonFileNames
			const pageJsonFileName = isMasterPage || isErrorPage ? pageJsonFileNames[MasterPageId] : pageJsonFileNames[pageCompId]
			// TODO - handle/catch site-assets client error
			return siteAssetsClient.execute(
				{
					moduleParams: bootstrapData.siteAssetsClientInitParams.modulesParams[resourceType],
					pageCompId,
					...(isErrorPage ? { pageCompId: isErrorPage ? 'masterPage' : pageCompId, errorPageId: pageCompId } : {}),
					pageJsonFileName: pageJsonFileName || bootstrapData.pageJsonFileName
				},
				'disable'
			)
		})

	function mergeConnections(masterPagePlatformModel: PlatformModel, platformModel: PlatformModel) {
		// merge connection arrays
		return _.mergeWith(platformModel.connections, masterPagePlatformModel.connections, (objValue, srcValue) => (_.isArray(objValue) ? objValue.concat(srcValue) : undefined))
	}

	async function getModels(): Promise<Models> {
		const models: [PlatformModel, PlatformModel, FeaturesResponse, FeaturesResponse] = await Promise.all([
			fetchModel<PlatformModel>('platform', true),
			fetchModel<PlatformModel>('platform', false),
			fetchModel<FeaturesResponse>('features', true),
			fetchModel<FeaturesResponse>('features', false)
		])
		const [
			masterPagePlatformModel,
			platformModel,
			{
				props: masterPagePageConfig,
				structure: { components: masterPageStructureModel }
			},
			{
				props: currentPageConfig,
				structure: { components: currentPageStructureModel }
			}
		] = models
		const pageConfig = _.merge({}, masterPagePageConfig, currentPageConfig)
		const connections = mergeConnections(masterPagePlatformModel, platformModel)
		const onLoadProperties = _.merge({}, masterPagePlatformModel.onLoadProperties, platformModel.onLoadProperties)
		const structureModel = _.assign({}, masterPageStructureModel, currentPageStructureModel)
		const applications = _.merge({}, masterPagePlatformModel?.applications, platformModel?.applications)
		const propsModel = pageConfig.render.compProps
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function getPageIdByCompId(compId: string) {
			return currentPageStructureModel[compId] ? bootstrapData.currentPageId : MasterPageId
		}

		function getControllerTypeByCompId(compId: string) {
			const appControllers = _.find(applications, (controllers) => !!controllers[compId])
			return _.get(appControllers, [compId, 'controllerType'], '')
		}

		function getCompIdByWixCodeNickname(nickname: string) {
			return _.get(connections, ['wixCode', nickname, 0, 'compId'])
		}

		const orderedControllers = ['wixCode', ...masterPagePlatformModel.orderedControllers.concat(platformModel.orderedControllers)]

		if (!bootstrapData.platformEnvData.window.isSSR) {
			handlers.registerOnPropsChangedHandler(bootstrapData.currentPageId, (changes: { [compId: string]: any }) => {
				_.map(changes, (newProps: any, compId: string) => {
					_.assign(propsModel[compId], newProps)
				})
			})
		}

		return {
			getControllerTypeByCompId,
			getPageIdByCompId,
			getCompIdByWixCodeNickname,
			pageConfig,
			propsModel,
			structureModel,
			platformModel: {
				connections,
				applications,
				orderedControllers,
				sdkData: _.assign({}, masterPagePlatformModel.sdkData, platformModel.sdkData),
				staticEvents: _.concat(masterPagePlatformModel.staticEvents, platformModel.staticEvents),
				compIdConnections: _.assign({}, masterPagePlatformModel.compIdConnections, platformModel.compIdConnections),
				containersChildrenIds: _.assign({}, masterPagePlatformModel.containersChildrenIds, platformModel.containersChildrenIds),
				onLoadProperties
			}
		}
	}

	return {
		getModels
	}
}
